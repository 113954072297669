import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { ChatBoxBodyStyle, LoaderStyles } from "../styles";
import { IMessages } from "../types";
import Loader from "./Loader";

type Props = {
  messages: IMessages[];
  isTyping: boolean;
};

const ChatBoxBody = ({ messages, isTyping }: Props) => {
  const messageEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageEl) {
      messageEl.current!.addEventListener("DOMNodeInserted", (event: any) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);

  return (
    <ChatBoxBodyStyle ref={messageEl}>
      {messages?.map(({ role, content, createdAt }, index: number) => (
        <div className={`chat-box-body-${role}`} key={index}>
          <p>{content}</p>
          <span>{dayjs(createdAt).format("HH:mm")}</span>
        </div>
      ))}
      <LoaderStyles>{isTyping ? <Loader /> : null}</LoaderStyles>
    </ChatBoxBodyStyle>
  );
};

export default ChatBoxBody;
