import { IconX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { ChatBoxHeaderStyle } from "../styles";

type Props = {
  isConnected: boolean;
  onClose: () => void;
};

const ChatBoxHeader = ({ isConnected, onClose }: Props) => {
  const { t } = useTranslation("common");

  return (
    <ChatBoxHeaderStyle>
      <h3>
        {t("common.title")} <br />{" "}
        <span>{isConnected ? t("common.online") : t("common.offline")}</span>
      </h3>
      <p onClick={onClose}>
        <IconX />
      </p>
    </ChatBoxHeaderStyle>
  );
};

export default ChatBoxHeader;
