import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";
import ChatBox from "./components/ChatBox";

function App() {
  const [currentLang, setCurrentLang] = useState<"ru" | "en">("ru");
  const { i18n } = useTranslation();

  const queryString = window.location.search;

  const scriptSrc = window.document
    .getElementById("chatlyScript")
    ?.getAttribute("src")
    ?.split("?")?.[1];

  const urlParams = new URLSearchParams(queryString);

  const urlServiceKey =
    urlParams.get("serviceKey") || scriptSrc?.replace("serviceKey=", "");

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  useEffect(() => {
    const i18 = localStorage.getItem("i18nextLng");

    const handleStorage = () => {
      setCurrentLang(i18 === "en" ? "en" : "ru");
    };

    if (i18 === "ru") {
      setCurrentLang("ru");
    } else if (i18 === "en") {
      setCurrentLang("en");
    } else if (
      window.location.pathname.split("/").find((lng) => lng === "en")
    ) {
      setCurrentLang("en");
    } else if (
      window.location.pathname.split("/").find((lng) => lng === "ru")
    ) {
      setCurrentLang("ru");
    } else {
      setCurrentLang("ru");
    }

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  return (
    <Wrapper>
      <ChatBox serviceKey={urlServiceKey || ""} currentLang={currentLang} />
    </Wrapper>
  );
}

export default App;
