import { IconSend } from "@tabler/icons-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { v4 as uuidv4 } from "uuid";
import { ChatBoxFooterStyle, ChatBoxStyle, ChatButtonStyle } from "../styles";
import { IMessages } from "../types";
import ChatBoxBody from "./ChatBoxBody";
import ChatBoxHeader from "./ChatBoxHeader";
import axios from "axios";

type Props = {
  currentLang: "ru" | "en";
  serviceKey: string;
};

let tomorrow = new Date();
let today = new Date();
tomorrow.setDate(today.getDate() + 1);

const ChatBox = ({ currentLang, serviceKey }: Props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["user-room"]);
  const [opened, setOpened] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<IMessages[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const { t } = useTranslation("common");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const room = cookies["user-room"];

  const socketUrl = `wss://chatly-ws.lavina.tech/chat?chatId=${room}&serviceKey=${serviceKey}`;

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 1000,
  });

  // send user message
  const handleClickSendMessage = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!message) return;

    if (message === "/clean") {
      removeCookie("user-room");
      setMessage("");
      setMessages([]);
      setCookie("user-room", uuidv4(), { expires: tomorrow });
      return;
    }

    sendMessage(message);
    setIsTyping(true);
    if (messages?.length) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: message, role: "user", createdAt: new Date() },
      ]);
    } else {
      setMessages([{ content: message, role: "user", createdAt: new Date() }]);
    }
    setMessage("");
    setTimeout(() => setIsTyping(false), 5000);
  };

  useEffect(() => {
    if (lastMessage) {
      setMessages([
        ...messages,
        {
          role: "assistant",
          content: lastMessage?.data,
          createdAt: new Date(),
        },
      ]);
      setIsTyping(false);
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  // get chat history
  useEffect(() => {
    if (room)
      axios
        .get(
          `https://chatly-back.lavina.tech/service/${serviceKey}/chats/${room}/history`,
          {
            headers: {
              "Accept-Language": `${currentLang}`,
            },
          }
        )
        .then((res) => {
          setMessages(res.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [room, currentLang]);

  // user chat room
  useEffect(() => {
    if (!cookies["user-room"]) {
      setCookie("user-room", uuidv4(), { expires: tomorrow });
    }
  }, []);

  const onEnterPress = (e: FormEvent<HTMLFormElement> | any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      handleClickSendMessage(e);
    }
  };

  useEffect(() => {
    if (opened) inputRef.current?.focus();
  }, [opened]);

  return (
    <>
      <ChatBoxStyle $visibility={`${opened ? "visible" : "hidden"}`}>
        <ChatBoxHeader
          isConnected={connectionStatus === "Open" ? true : false}
          onClose={() => setOpened(false)}
        />
        <ChatBoxBody messages={messages} isTyping={isTyping} />
        <ChatBoxFooterStyle onSubmit={handleClickSendMessage}>
          <textarea
            placeholder={t("common.input.placeholder")}
            rows={3}
            autoFocus={true}
            ref={inputRef}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          />
          <button type="submit">
            <IconSend />
          </button>
        </ChatBoxFooterStyle>
      </ChatBoxStyle>
      <ChatButtonStyle
        $text={t("common.button")}
        onClick={() => setOpened(true)}
      >
        <span></span>
      </ChatButtonStyle>
    </>
  );
};

export default ChatBox;
