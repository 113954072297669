import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

  box-sizing: unset;
`;

export const ChatBoxStyle = styled.div<{ $visibility?: "visible" | "hidden" }>`
  height: 90%;
  width: 400px;
  position: fixed;
  margin: 0 auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 9999;
  box-shadow: inset 0 1px 1px rgb(0 0 0/7%), 0 2px 2px rgb(0 0 0/7%),
    0 4px 4px rgb(0 0 0/7%), 0 8px 8px rgb(0 0 0/7%), 0 16px 16px rgb(0 0 0/7%);
  right: 0;
  bottom: 0;
  margin: 15px;
  background: #fff;
  border-radius: 15px;
  visibility: ${(props) => props.$visibility};

  @media (max-width: 450px) {
    & {
      width: 100% !important;
      height: 100%;
      margin: 0 !important;
      border-radius: 0px !important;
    }
  }
`;

export const ChatBoxHeaderStyle = styled.div`
  height: 8%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  font-size: 14px;
  padding: 0.5em 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 -1px 10px rgba(172, 54, 195, 0.3);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.025);

  h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    float: left;
    position: absolute;
    left: 25px;

    span {
      font-size: 14px;
      color: #90949c;
    }
  }

  p {
    float: right;
    position: absolute;
    right: 16px;
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`;

export const ChatBoxBodyStyle = styled.div`
  height: 75%;
  background: #f8f8f8;
  overflow-y: scroll;
  padding: 12px;

  .chat-box-body-user {
    width: 250px;
    float: right;
    background: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.015);
    margin-bottom: 14px;

    p {
      margin: 0;
      color: #444;
      font-size: 18px;
      margin-bottom: 0.25rem;
      font-family: "Roboto", sans-serif;
    }

    span {
      float: right;
      color: #777;
      font-size: 10px;
    }
  }

  .chat-box-body-assistant {
    width: 250px;
    float: left;
    background: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.015);
    margin-bottom: 14px;

    p {
      margin: 0;
      color: #444;
      font-size: 18px;
      margin-bottom: 0.25rem;
      font-family: "Roboto", sans-serif;
    }

    span {
      float: right;
      color: #777;
      font-size: 10px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    opacity: 0;
  }
`;

export const ChatBoxFooterStyle = styled.form`
  position: relative;
  display: flex;

  button {
    border: none;
    font-size: 14px;
    background: white;
    color: #000;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  textarea {
    padding: 10px;
    border: none;
    -webkit-appearance: none;
    border-radius: 15px;
    background: whitesmoke;
    margin: 15px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #444;
    width: 300px;
    resize: none;

    @media (max-width: 450px) {
      & {
        width: 280;
        margin-right: 0;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .send-button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    vertical-align: middle;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
  }
`;

export const ChatButtonStyle = styled.div<{ $text: string }>`
  padding: 25px 16px;
  background: #2c50ef;
  width: 130px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 2px 15px rgba(#2c50ef, 0.21);
  cursor: pointer;
  z-index: 999;

  span::before {
    content: "";
    height: 15px;
    width: 15px;
    background: #47cf73;
    position: absolute;
    transform: translate(0, -7px);
    border-radius: 15px;
    box-sizing: unset;
  }

  span::after {
    content: "${(props) => props.$text}";
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: white;
    position: absolute;
    left: 40px;
    top: 18px;
    box-sizing: unset;
  }
`;

const mercuryTypingAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0px);
  }`;

export const LoaderStyles = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .tiblock {
    align-items: center;
    display: flex;
    height: 17px;
  }

  .ticontainer .tidot {
    background-color: #90949c;
  }

  .tidot {
    animation: ${mercuryTypingAnimation} 1.5s infinite ease-in-out;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin-right: 4px;
    width: 8px;
  }

  .tidot:nth-child(1) {
    animation-delay: 200ms;
  }
  .tidot:nth-child(2) {
    animation-delay: 300ms;
  }
  .tidot:nth-child(3) {
    animation-delay: 400ms;
  }
`;
