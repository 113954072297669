import i18next from "./i18n";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";

const chatly = document.createElement("div");
chatly.setAttribute("id", "chatly");

document.body.appendChild(chatly);

const root = ReactDOM.createRoot(
  document.getElementById("chatly") as HTMLElement
);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
