import i18next from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/common.json";
import ru from "./locales/ru/common.json";

i18next
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    default: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ru: {
        common: ru,
      },
      en: {
        common: en,
      },
    },
  });

export default i18next;
